import React, { useState, useEffect } from 'react';
import LoginAPI from "../../api/loginApi";
import ErrorMessageBox from "../common/ErrorMessageBox";

function Moodle(){

    const [url, setUrl] = useState("");
    const [showHelperText, setHelperText] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    

    useEffect(() =>{
        LoginAPI.getLoggedIn()
        .then((result)=>{
            if(result.status===200){
                result.json().then(resultBody=>{
                    localStorage.setItem('userRole', resultBody.userRole);
                })
                LoginAPI.getMoodle()
                .then((result)=>{
                    if(result.status===200){
                        result.text().then(((resultBody)=>{
                            setUrl(resultBody);
                            if(localStorage.getItem('userRole')!=="USER_ROLE_ADMIN")
                                window.location.href=resultBody
                        }))
                    }else{
                        setHelperText(true);
                        result.text().then((resultBody)=>{
                            console.log(resultBody)
                            setErrorMessage(resultBody);
                        }
                    )}
                })
          }else{
            setHelperText(true);
            result.text().then((resultBody)=>{
                console.log(resultBody)
                setErrorMessage(resultBody);
            })
        }
        })
    },[])

    return(
        <div style={{height:"100vh", minHeight:"100%"}} >
            {showHelperText? ErrorMessageBox(errorMessage) : null}
            <a href={url}>Click here for Moodle</a>
        </div>
    )

} export default Moodle;