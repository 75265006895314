import React, { useState, useEffect } from 'react';
import LoginAPI from "../../api/loginApi";
import {Table, TableCell} from 'semantic-ui-react';

function Roll(props){

    // const [roll, setRoll] = useState([]);
    const [user, setUser] = useState([]);
    const [course, setCourse] = useState([]);
    const [attendanceHistory, setattendanceHistory] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        LoginAPI.getLoggedIn()
        .then((result)=>{
            if(result.status===200){
                LoginAPI.getRoll(props.match.params.id)
                .then((result)=>{
                    // setRoll(result);
                    result.forEach((courses)=>{
                        setUser(user => [...user, courses.user])
                        setCourse(course => [...course, courses.course])
                        setattendanceHistory(attendanceHistory => [...attendanceHistory, courses.attendanceHistory])
                    })
                    setLoading(false);
            })
          }
        })
    },[props.match.params.id])

    if(loading){
        return(<div>loading...</div>)
    }else{

        let headerWeekRow = [];
        for(let i =0; i < course[0].periods; i++){
        headerWeekRow.push(<Table.HeaderCell key={i}>week {i+1}</Table.HeaderCell>)
        }

    return(
        <div style={{marginLeft:"10px"}}>
            <div style={{marginTop:"15px", marginBottom:"40px", fontSize:"40px"}}>{course[0].fullname}</div>
            <Table>
                <Table.Header>  
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>  
                        {headerWeekRow}
                        <Table.HeaderCell>Notes</Table.HeaderCell>                   
                    </Table.Row>
                </Table.Header>
                    <Table.Body>
                        {user.map((usered, i)=>{

                            let studentAttendance=[];
                            let studentAttendanceNotes=[];

                            attendanceHistory[i].forEach((attendance, j)=>{
                                let converted=""
                                let cellColour=""
                                if(attendance.attendance===0){
                                    converted="A"
                                    cellColour="lightcoral"
                                }else if(attendance.attendance===1){
                                    converted="L"
                                    cellColour="lightyellow"
                                }else if(attendance.attendance===2){
                                    converted="P"
                                    cellColour="lightgreen"
                                }
                                studentAttendance.push(<TableCell key={"attendance"+j} style={{backgroundColor:cellColour}}>{converted}</TableCell>)
                                studentAttendanceNotes.push(<br key={"break"+j} />,`week ${j+1}: `,attendance.notes)
                            })
                            return(<Table.Row key={i}>
                                        <Table.Cell>{usered.username}</Table.Cell>
                                        {studentAttendance}
                                        <Table.Cell>{studentAttendanceNotes}</Table.Cell>
                                        </Table.Row>)

                        })}                        
                    </Table.Body>
            </Table>

            {/* <div> {roll.map((courses)=>
                <>
                    <br />
                        test {props.match.params.id}
                    <div>user: {courses.user.firstname}</div>
                    <div>attendance: {courses.attendanceHistory.map((attendance)=>
                        <div style={{paddingLeft: "20px"}}>
                            <div>week: {attendance.week}</div>
                            <div>attendance History: {attendance.attendance}</div>
                        </div>)
                        } 
                    </div>

                </>
            )
            
            }
            </div> */}
        </div>
    )
    }

} export default Roll;