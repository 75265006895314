import React, { useState, useEffect } from 'react';
import LoginAPI from "../../api/loginApi";
import {Link} from "react-router-dom";
import {Card, Button} from "@patternfly/react-core";
import ErrorMessageBox from "../common/ErrorMessageBox";
import SockJsClient from 'react-stomp';

function AdminPage(){
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showHelperText, setHelperText] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState([])

    useEffect(() =>{
        LoginAPI.getLoggedIn()
        .then((result)=>{
            if(result.status===200){
                LoginAPI.getCurrentCourse()
                .then((result)=>{
                    setCourses(result);
                    setLoading(false);
            })
          }else{
            setHelperText(true);
            result.text().then((resultBody)=>{
                console.log(resultBody)
                setErrorMessage(resultBody);
                setLoading(false);
            }
        )}
        })
    },[])

    const syncCourse = () =>{
        LoginAPI.getSync()
    }

    const handleMessage = (msg) => {
        console.log(msg);
        setMessage([...message, <div>{msg}</div>])
    }

    if(loading){
        return(<div>loading...</div>)
    }else{
    return(
        <div style={{marginLeft:"10px", display:"flex",flexDirection:"row"}}>
            {showHelperText? ErrorMessageBox(errorMessage) : null}
            <div style={{width:"50%"}}>
                <div style={{marginTop:"15px", marginBottom:"15px", fontSize:"40px", width:"50%"}}>Courses</div>
                <div> {courses.map((course, i)=> 
                <div style={{width:"25vw"}} key={i}>
                    <br />
                    <Card style={{padding:"5px 5px 5px 5px"}}>
                        <div>Course name: <Link to={'roll/'+course.shortname}>{course.shortname}</Link></div>
                        <div>{course.fullname}</div>
                        <div>Start date:{course.startDate}</div>
                        <div>Start time: {course.startTime}</div>
                        <div>End time: {course.endTime}</div>
                        <div>Periods: {course.periods}</div>
                        <div>Current period: {course.currentPeriod}</div>
                    </Card>
                </div>
                )}
                </div>
            </div>
            <div style={{marginTop:"15px", marginBottom:"15px", fontSize:"40px", width:"50%"}}>
            <Button onClick={syncCourse}>Sync</Button>
            <Card>
            <SockJsClient url='https://online.northshore-lidcombe.com.au/ws'
            topics={['/topic/sync']} onMessage={(msg)=>{handleMessage(msg)}}>
                
            </SockJsClient>
            {message}

            </Card>
            </div>
        </div>
    )
    }

} export default AdminPage;