import React, {useState} from 'react';
import {Modal, Button, Form, FormGroup, TextInput, ActionGroup, AlertGroup, AlertVariant, Alert} from '@patternfly/react-core';
import loginAPI from '../../api/loginApi.js';
import { ExclamationCircleIcon } from '@patternfly/react-icons';

function LoginModal(props){
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUsername, setIsUsername] = useState("");
    const [isMobile, setIsMobile] = useState("");
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isCode, setIsCode] = useState("");
    const [isPassword, setIsPassword] = useState("");
    const [showHelperText, setHelperText] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [allSet, isAllSet] = useState(false);

    const handleOpen = () => {
        setIsModalOpen(!isModalOpen)
    }

    const getCode = () => {
        loginAPI.callMobileLogIn(isUsername, isMobile)
        .then((result)=>{
            console.log(result);
            if(result.status===200){
                setIsCodeSent(true);
            }else{
                setHelperText(true);
                result.text().then((resultBody)=>{
                    console.log(resultBody)
                    setErrorMessage(resultBody);
                })
            }
        })
    }

    const setPassword = () => {
        loginAPI.setPassword(isUsername, isCode, isPassword)
        .then((result)=>{
            console.log(result);
            if(result.status===200){
                isAllSet(true);
            }else{
                setHelperText(true);
                result.text().then((resultBody)=>{
                    console.log(resultBody)
                    setErrorMessage(resultBody);
                })
            }
        })
    }

    const helperText = (
        <div style={{color:"red"}}>
          <ExclamationCircleIcon />
          &nbsp;{errorMessage}
        </div>
    );

    const getCodeView = ( 
    <>
        <Button onClick={handleOpen}>Set Password</Button>
        <Modal
        isSmall
        title="Set Password"
        isOpen={isModalOpen}
        onClose={handleOpen}
        actions={[
            <Button key="cancel" onClick={handleOpen}>Cancel</Button>
        ]}
        isFooterLeftAligned
        >
            {showHelperText ? helperText : null}
            <Form>
                <FormGroup
                label="Username"
                isRequired
                fieldId="form-username"
                helperText="Enter your username"
                >
                    <TextInput
                        isRequired
                        id="username"
                        type="text"
                        name="form-username"
                        aria-describedby="form-username"
                        value={isUsername}
                        onChange={setIsUsername}
                        />          
                </FormGroup>
                <FormGroup
                label="Mobile"
                isRequired
                fieldId="form-mobile"
                helperText="Enter your mobile"
                >
                    <TextInput
                        isRequired
                        id="password"
                        type="text"
                        name="form-mobile"
                        aria-describedby="form-mobile"
                        value={isMobile}
                        onChange={setIsMobile}
                        />          
                </FormGroup>
                <ActionGroup>
                    <Button onClick={getCode}>Get Code</Button>
                </ActionGroup>
            </Form>
        </Modal>
    </>

    )

    const setPasswordView = ( 
        <>
            <Modal
            isSmall
            title="Set Password"
            isOpen={isModalOpen}
            onClose={handleOpen}
            actions={[
                <Button key="cancel" onClick={handleOpen}>{allSet?<>Close</>:<>Cancel</>}</Button>
            ]}
            isFooterLeftAligned
            >
                {allSet ? <AlertGroup><Alert title="Password set" variant={AlertVariant['success']} isInline/></AlertGroup> : 
                <>{showHelperText ? helperText : null}
                    <Form>
                        <FormGroup
                        label="Code"
                        isRequired
                        fieldId="form-code"
                        helperText="Enter your code"
                        >
                            <TextInput
                                isRequired
                                id="code"
                                type="text"
                                name="form-code"
                                aria-describedby="form-code"
                                value={isCode}
                                onChange={setIsCode}
                                />          
                        </FormGroup>
                        <FormGroup
                        label="Password"
                        isRequired
                        fieldId="form-password"
                        helperText="Enter your new password"
                        >
                            <TextInput
                                isRequired
                                id="password"
                                type="password"
                                name="form-password"
                                aria-describedby="form-password"
                                value={isPassword}
                                onChange={setIsPassword}
                                />          
                        </FormGroup>
                        <ActionGroup>
                            <Button onClick={setPassword}>Set Password</Button>
                        </ActionGroup>
                    </Form>
                </>
            }
            </Modal>
        </>
    
        )

return(
    isCodeSent ? setPasswordView : getCodeView
)

} export default LoginModal;