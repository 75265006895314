import React from 'react';
import {Card, Button} from "@patternfly/react-core";

function CourseNotFound(){
    let images = require("../../images/books.jpg");

    const goBackFunction = () =>{
        window.history.back();
    }

    return(
    <div style={{fontSize:"40px", height:"100vh", width:"100wh", backgroundImage:`url(${images})`, backgroundSize: "cover"}}>
        <Card style={{marginTop:"15px", marginBottom:"15px", fontSize:"40px", width:"50%"}}>
            Your live lesson is currently not running. Live lessons are available 15 minutes before the start of the lesson. 
            <br />
            <Button onClick={goBackFunction}>Click here to go back to Moodle</Button>
        </Card>
    </div>)

}export default CourseNotFound;