import React, { useState } from 'react';
import {
    LoginForm,
    LoginPage
 } from '@patternfly/react-core';
import LoginModal from './LoginModals'
import {Redirect} from 'react-router-dom';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import loginAPI from '../../api/loginApi.js';


function Login(){
const [showHelperText, setHelperText] = useState(false);
const [usernameValue, setUsernameValue] = useState('');
const [isValidUsername, setValidUsername] = useState(true);
const [passwordValue, setPasswordValue] = useState('');
const [isValidPassword, setValidPassword] = useState(true);
const [isLoggedIn, setisLoggedIn] = useState(false);
const [useMobile, setuseMobile] = useState(true);
const [isCode, setIsCode] = useState('');
const [isValidCode, setValidCode] = useState(true);
const [isCodeSent, setIsCodeSent] = useState(false);
const [errorMessage, setErrorMessage] = useState("");


const onLoginButtonClick = event =>{
    event.preventDefault();
    setValidUsername(!!usernameValue);
    setValidPassword(!!passwordValue);
    setHelperText(!usernameValue || !passwordValue);

    if(useMobile){
        loginAPI.callMobileLogIn(usernameValue, passwordValue)
        .then((result)=>{
            if(result.status===200){
                setIsCodeSent(true);
            }else{
                setHelperText(true);
                result.text().then((resultBody)=>{
                    console.log(resultBody)
                    setErrorMessage(resultBody);
                })
            }
        })
    }else{
        loginAPI.callPasswordLogIn(usernameValue, passwordValue)
        .then((result)=>{
            if(result.status===200){
                result.text().then((resultBody)=>{
                    localStorage.setItem('token', resultBody);
                    setisLoggedIn(true);
                })
            }else{
                setHelperText(true);
                result.text().then((resultBody)=>{
                    console.log(resultBody)
                    setErrorMessage(resultBody);
                })
            }
        })
    }
}

const onCodeLoginButtonClick = event =>{
    event.preventDefault();
    setValidCode(!!isCode);
    loginAPI.callMobileCode(usernameValue, isCode)
        .then((result)=>{
            if(result.status===200){
                result.text().then((resultBody)=>{
                    localStorage.setItem('token', resultBody);
                    setisLoggedIn(true);
                })
            }else{
                setHelperText(true);
                result.text().then((resultBody)=>{
                    console.log(resultBody)
                    setErrorMessage(resultBody);
                })
            }
        })
}

const switchView = () =>{
    setuseMobile(!useMobile)
}

const helperText = (
    <div style={{color:"red"}}>
      <ExclamationCircleIcon />
      &nbsp;{errorMessage}
    </div>
);

const loginFormMobile = (
    <>
        <LoginForm
        showHelperText={showHelperText}
        helperText={helperText}
        usernameLabel="Username"
        usernameValue={usernameValue}
        onChangeUsername={setUsernameValue}
        isValidUsername={isValidUsername}
        passwordLabel="Mobile"
        passwordValue={passwordValue}
        onChangePassword={setPasswordValue}
        isValidPassword={isValidPassword}
        onLoginButtonClick={onLoginButtonClick}
        />
        <br />
        <div style={{color:"blue", cursor:"pointer"}} onClick={switchView}> Use password to log in</div>
    </>
  );

  const loginFormCode = (
    <>
        <LoginForm
        showHelperText={showHelperText}
        helperText={helperText}
        usernameLabel="Username"
        usernameValue={usernameValue}
        passwordLabel="Code"
        passwordValue={isCode}
        onChangePassword={setIsCode}
        isValidPassword={isValidPassword}
        onLoginButtonClick={onCodeLoginButtonClick}
        />
    </>
  );

  const loginFormPassword = (
    <>
        <LoginForm
        showHelperText={showHelperText}
        helperText={helperText}
        usernameLabel="Username"
        usernameValue={usernameValue}
        onChangeUsername={setUsernameValue}
        isValidUsername={isValidUsername}
        passwordLabel="Password"
        passwordValue={passwordValue}
        onChangePassword={setPasswordValue}
        isValidPassword={isValidCode}
        onLoginButtonClick={onLoginButtonClick}
        />
        <br />
        <div style={{color:"blue", cursor:"pointer"}} onClick={switchView}>
            Use mobile to log in
        </div>
    </>
  );

  const listItem=(
      <>
        <LoginModal/>
      </>

  )

  const sideText=(
  <>
    <div style={{fontSize:"30px"}}>Northshore Lidcombe - Online</div>
    <br />
    <div>
        Welcome to Northshore Lidcombe - online
        <br />
        <br />
        To access online services you must have an active enrollment, please contact (02) 9649 2959 for more information.
    </div>
  </>)
  

  return (
    isLoggedIn ? <Redirect to="/Moodle" /> :
        <LoginPage 
            backgroundImgSrc={require("../../images/penclis.jpeg")}
            backgroundImgAlt="Images"
            loginTitle="Log in to your account"
            loginSubtitle="Please log in with you username and password/mobile"
            textContent={sideText}
            footerListItems={listItem}
        >
            {isCodeSent ? loginFormCode : useMobile ? loginFormMobile : loginFormPassword}
        </LoginPage>
)

} export default Login;