import React, { useState, useEffect } from 'react';
import LoginAPI from "../../api/loginApi";
import {Link} from "react-router-dom";
import {Card} from "@patternfly/react-core";
import ErrorMessageBox from "../common/ErrorMessageBox";

function Dashboard(){

    const [enrollments, setEnrollments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showHelperText, setHelperText] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() =>{
        LoginAPI.getLoggedIn()
        .then((result)=>{
            if(result.status===200){
                LoginAPI.getEnrollments()
                .then((result)=>{
                    setEnrollments(result);
                    setLoading(false);
            })
          }else{
            setHelperText(true);
            result.text().then((resultBody)=>{
                console.log(resultBody)
                setErrorMessage(resultBody);
                setLoading(false);
            }
        )}
        })
    },[])

    if(loading){
        return(<div>loading...</div>)
    }else{
    return(
        <div style={{marginLeft:"10px"}}>
            {showHelperText? ErrorMessageBox(errorMessage) : null}
            <div style={{marginTop:"15px", marginBottom:"15px", fontSize:"40px"}}>Courses</div>
            <div> {enrollments.map((courses, i)=>
            <div style={{width:"25vw"}} key={i}>
                <br />
                <Card style={{padding:"5px 5px 5px 5px"}}>
                    <div>Course name: <Link to={'roll/'+courses.shortname}>{courses.shortname}</Link></div>
                    <div>{courses.fullname}</div>
                    <div>Start date:{courses.startDate}</div>
                    <div>Start time: {courses.startTime}</div>
                    <div>End time: {courses.endTime}</div>
                    <div>Periods: {courses.periods}</div>
                    <div>Current period: {courses.currentPeriod}</div>
                </Card>
            </div>
            )}
            </div>
        </div>
    )
    }

} export default Dashboard;