import React, {useState} from 'react';
import {
  Route,
  Redirect,
  Link
} from 'react-router-dom';
import {
    Nav,
    NavItem,
    NavList,
    NavVariants,
    PageHeader
  } from '@patternfly/react-core';
import loginAPI from '../api/loginApi.js';
  

export const NavHub = () => {

    const [activeItem, setActiveItem] = useState(0);

    const onSelect = result => {
        setActiveItem(result.itemId);
    }

    let noStudentSee=(        
        <NavItem key={1} itemID={1} isActive={activeItem === 1}>
            <Link to="/Dashboard">Dashboard</Link>
        </NavItem>
    );

    if(localStorage.getItem('userRole')==="USER_ROLE_STUDENT"){
        noStudentSee=(<NavItem key={1} itemID={1} isActive={activeItem === 1}></NavItem>)

    }

    let adminPage=(<NavItem key={2} itemID={2} isActive={activeItem === 2}></NavItem>)

    if(localStorage.getItem('userRole')==="USER_ROLE_ADMIN"){
        adminPage=(<NavItem key={2} itemID={2} isActive={activeItem === 2}>
            <Link to="/Admin">Admin</Link>
            </NavItem>)

    }

const logOut=()=>{
    localStorage.removeItem('token');
    loginAPI.logOut();
}

    const nav = (    
    <Nav onSelect={onSelect} style={{paddingLeft:"10px"}}>
        <NavList variant={NavVariants.horizontal}>
        <NavItem key={0} itemID={0} isActive={activeItem === 0}>
            <Link to="/Moodle">Moodle</Link>
        </NavItem>
        {noStudentSee}
        {adminPage}
        <NavItem key={3} itemID={3} isActive={activeItem === 3} onClick={logOut}>
            <Link to="/">Log out</Link>
        </NavItem>
        </NavList>
    </Nav>)

    return(
        <PageHeader topNav={nav} style={{backgroundColor:'rgb(21, 21, 21)'}} />
    )
}


export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props)=>(
    localStorage.getItem('token')
      ?  <>
            <NavHub />
            <Component {...props}/>
        </>
      : <Redirect to={{ pathname: '/', state: {from: props.location} }} />
  )} />
)
