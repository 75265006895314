import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Login from './components/login/Login';
import Dashboard from './components/dashboard/Dashboard';
import Moodle from './components/moodle/Moodle';
import Roll from './components/roll/Roll';
import {PrivateRoute} from'./modules/PrivateRoute';
import CourseNotFound from './components/notfound/CourseNotFound.js';
import AdminPage from './components/admin/AdminPage';
import pageNotFound from './components/notfound/pageNotFound.js'

function App() {
  document.title = "Northshore Lidcombe - Online"
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute path="/Moodle" component={Moodle} />
        <PrivateRoute path="/Dashboard" component={Dashboard} />
        <PrivateRoute path="/Roll/:id" component={Roll} />
        <PrivateRoute path="/Admin" component={AdminPage} />
        <PrivateRoute path="/CourseNotFound" component={CourseNotFound} />
        <PrivateRoute component={pageNotFound} />
      </Switch>
    </Router>
  );
}

export default App;
