import React from 'react';


function pageNotFound(){
    let images = require("../../images/books.jpg");
    return(
    <div style={{fontSize:"40px", height:"100vh", width:"100wh", backgroundImage:`url(${images})`, backgroundSize: "cover"}}>
        Page not found
    </div>)

}export default pageNotFound;