import {baseurl} from './baseurl.js'
const url = baseurl 

class loginAPI {

    static callMobileLogIn(username, mobile){
        let formdata = new FormData();
        formdata.append("username",username);
        formdata.append("mobile",mobile);
        
            return fetch(url+"/login/mobile",
            {
            method: "POST",
            body: formdata,
            }).then((response)=>{
                return response});
    }

    static callMobileCode(username, code){
        let formdata = new FormData();
        formdata.append("username",username);
        formdata.append("code",code);
        
            return fetch(url+"/login/mobile/code",
            {
            method: "POST",
            body: formdata,
            }).then((response)=>{
                return response});
    }

    static callPasswordLogIn(username, password){
        let formdata = new FormData();
        formdata.append("username",username);
        formdata.append("password",password);
        
            return fetch(url+"/login/password",
            {
            method: "POST",
            body: formdata,
            }).then((response)=>{
                return response});
    }

    static setPassword(username, code, password){
        let formdata = new FormData();
        formdata.append("username",username);
        formdata.append("code",code);
        formdata.append("password",password);
        
            return fetch(url+"/login/password/set",
            {
            method: "POST",
            body: formdata,
            }).then((response)=>{
                return response});
    }

    static getMoodle(){
        let myHeaders = new Headers();
        myHeaders.append("x-auth-token",localStorage.getItem('token'));

            return fetch(url+"/users/moodle",
            {
                headers: myHeaders,
            })
            .then((response)=>{
                return response});
    }

    static getLoggedIn(){
        let myHeaders = new Headers();
        myHeaders.append("x-auth-token",localStorage.getItem('token'));

        return fetch(url+"/users/loggedin",
        {
            headers: myHeaders,
        })
        .then((response)=>{
            return response});
    }

    static getEnrollments(){
        let myHeaders = new Headers();
        myHeaders.append("x-auth-token",localStorage.getItem('token'));
    
        return fetch(url+"/users/enrollments",
        {
          headers: myHeaders,
        })
        .then((response) =>{
             return response.json();
          })
      }

    static getRoll(shortName){

    let myHeaders = new Headers();
    myHeaders.append("x-auth-token",localStorage.getItem('token'));

    return fetch(url+"/staff/roll?shortname="+shortName,
    {
        headers: myHeaders,
    })
    .then((response) =>{
            return response.json();
        })
    }
    
    static getCurrentCourse(){

        let myHeaders = new Headers();
        myHeaders.append("x-auth-token",localStorage.getItem('token'));
    
        return fetch(url+"/admin/course/current",
        {
            headers: myHeaders,
        })
        .then((response) =>{
                return response.json();
            })
        }

    static getAllCourses(){

        let myHeaders = new Headers();
        myHeaders.append("x-auth-token",localStorage.getItem('token'));
    
        return fetch(url+"/admin/courses/all",
        {
            headers: myHeaders,
        })
        .then((response) =>{
                return response.json();
            })
        }

    static getSync(){

        let myHeaders = new Headers();
        myHeaders.append("x-auth-token",localStorage.getItem('token'));
    
        return fetch(url+"/admin/sync",
        {
            headers: myHeaders,
        })
        .then((response) =>{
                return response.json();
            })
        }

    static logOut(){

        let myHeaders = new Headers();
        myHeaders.append("x-auth-token",localStorage.getItem('token'));
    
        return fetch(url+"/login/logout",
        {
            headers: myHeaders,
        })
        .then((response) =>{
                return response.json();
            })
        }
    
      

} export default loginAPI